import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  MobileStepper,
  Step as StepperStep,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { defer } from 'lodash';
import { AlertMessage, MainHeader } from '@eposnow/ui-core';
import { getStepConfig, StepConfig } from '../steps/config/steps';
import ErrorLoadingData from './ErrorLoadingData';
import { businessTypes, CompanyInfo, CurrentStep, HOPUrls, Step } from '../types';
import { UIContext } from '../context/UIContext';
import Fallback from './Fallback';
import { UserContext } from '../context/UserContext';
import ServerAlertMessage from './ServerAlertMessage';
import { useUserCountry } from '../helpers/useCountries';

// eslint-disable-next-line no-shadow -- bug in eslint when exporting enums
export enum PrepStage {
  SELECT_BUSINESS_TYPE,
  PREPARE_KYC,
  CONFIRM_KYC,
  KYC_IN_PROGRESS,
  CONFIRM_KYC_COMPLETE,
  PROVIDE_SOLE_TRADER_NAME,
  LOADING_HOP_URL,
}

// eslint-disable-next-line no-shadow -- bug in eslint when exporting enums
export enum StepEvent {
  MOVE_NEXT,
  MOVE_BACK,
}

const StepController = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userData } = useAuth();
  const { apiFetch, locale } = useContext(UserContext);
  const { isSmallDevice, noStepsDevice, isPOSApp, colorMode } = useContext(UIContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isLoading: isLoadingUserCountry,
    userCountry,
    isHop,
    isPrelim,
    helpScreenAvailable,
  } = useUserCountry();
  const [config, setConfig] = useState<StepConfig>({} as StepConfig);
  const [steps, setSteps] = useState<Step[]>([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(-1);
  const [finishedAllSteps, setFinishedAllSteps] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [allStepsCompleted, setAllStepsCompleted] = useState(false);
  const [noMoreSteps, setNoMoreSteps] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [businessType, setBusinessType] = useState<string | undefined>(undefined);
  const [prepStage, setPrepStage] = useState<PrepStage>(PrepStage.SELECT_BUSINESS_TYPE);
  const [kycUrl, setKycUrl] = useState<string>('');
  const [kycUrlError, setKycUrlError] = useState(false);
  const [isToMoveToNextStep, setIsToMoveToNextStep] = useState(false);
  const [updatedStartAt, setUpdatedStartAt] = useState(false);
  const messageRef = useRef<null | HTMLElement>(null);
  const [showAlertMessageGetCompanyInfo, setShowAlertMessageGetCompanyInfo] = useState(false);
  const [showAlertMessageUpdateCompanyInfo, setShowAlertMessageUpdateCompanyInfo] = useState(false);
  const [showAlertMessageLoadHopUrl, setShowAlertMessageLoadHopUrl] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loadingHop, setLoadingHop] = useState(false);
  const [isMyBusinessAccount, setIsMyBusinessAccount] = useState<boolean | null>(null);

  const [currentStep, setCurrentStep] = useState<CurrentStep>({
    completable: false,
    error: '',
  } as CurrentStep);
  const queryClient = useQueryClient();

  const setNewStep = (index: number) => {
    setCurrentStepIndex(index);
    setConfig(getStepConfig(steps[index]));
  };

  const updateCurrentStep = (data: any) => {
    if (data.error) {
      window.scrollTo(0, 0); // scroll to top to show the error
    }
    setCurrentStep({ ...currentStep, ...data });
  };

  useEffect(() => {
    if (currentStep.error && messageRef.current)
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [currentStep.error]);

  const navigateAndReset = (path: string, replace = false) => {
    window.scrollTo(0, 0); // scroll to top to show next step
    setShowAlertMessageGetCompanyInfo(false);
    setShowAlertMessageUpdateCompanyInfo(false);
    setKycUrlError(false);
    setUpdatedStartAt(false);
    setIsToMoveToNextStep(false);
    setCurrentStep({ completable: false, error: '' });
    navigate(path, { replace });
  };

  const goToNextStep = () => {
    if (finishedAllSteps) {
      window.open(`${process.env.REACT_APP_LINK_ORGANISATIONS}welcome-hub`, '_self');
      return;
    }
    if (!steps[currentStepIndex].completedAt && !steps[currentStepIndex].skippedAt) return;
    if (
      steps[currentStepIndex].stepKey === 'payments-verification' &&
      isMyBusinessAccount === true
    ) {
      window.open(`${process.env.REACT_APP_LINK_MY_MONEY_ADMIN}onboarding?backbook=true`, '_self');
      return;
    }
    if (isLastStep) {
      setFinishedAllSteps(true);
      setNoMoreSteps(steps.findIndex((step) => !step.completedAt) === -1);
      navigateAndReset('/payments-complete');
    } else navigateAndReset(`/${steps[currentStepIndex + 1].stepKey}`);
  };

  const goToPrevStep = () => {
    if (
      location.pathname === '/payments-verification' &&
      prepStage !== PrepStage.SELECT_BUSINESS_TYPE
    ) {
      setPrepStage(PrepStage.SELECT_BUSINESS_TYPE);
      return;
    }
    if (noMoreSteps) return;
    if (finishedAllSteps) navigateAndReset(`/${steps[steps.length - 1].stepKey}`);
    else if (currentStepIndex > 0) navigateAndReset(`/${steps[currentStepIndex - 1].stepKey}`);
  };

  const {
    refetch: fetchData,
    data: stepsData,
    isLoading,
    isError,
    error,
  } = useQuery<Step[]>(
    'steps',
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/steps`,
        false
      ),
    {
      staleTime: 0,
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        if ((err as any)?.code === 403) {
          navigate('/no-steps', { replace: true });
          return;
        }
        Sentry.captureException(new Error('Error fetching steps'), {
          extra: { error: JSON.stringify(err) },
        });
      },
    }
  );

  useEffect(() => {
    if (stepsData) {
      if (stepsData.length === 0) navigate('/no-steps', { replace: true });
      setSteps(stepsData);
    }
  }, [stepsData]);

  const { isLoading: isUpdatingStepSkipped, mutate: updateStepSkipped } = useMutation(
    (status: string) =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/steps/${steps[currentStepIndex].stepKey}/status`,
        false,
        { status },
        'POST'
      ),
    {
      async onSuccess() {
        setIsToMoveToNextStep(true);
        await queryClient.invalidateQueries('steps');
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error updating step skipped'), {
          extra: { error: JSON.stringify(err) },
        });
        updateCurrentStep({ error: t('errors.errorSkippingStep') });
      },
    }
  );

  const { isLoading: isUpdatingStepCompleted, mutate: updateStepCompleted } = useMutation(
    (status: string) =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/steps/${steps[currentStepIndex].stepKey}/status`,
        false,
        { status },
        'POST'
      ),
    {
      async onSuccess() {
        setIsToMoveToNextStep(true);
        await queryClient.invalidateQueries('steps');
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error updating step completed'), {
          extra: { error: JSON.stringify(err) },
        });
        updateCurrentStep({ error: t('errors.errorCompletingStep') });
      },
    }
  );

  const { mutate: updateStepStarted } = useMutation(
    (status: string) =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/steps/${steps[currentStepIndex].stepKey}/status`,
        false,
        { status },
        'POST'
      ),
    {
      async onSuccess() {
        setUpdatedStartAt(true);
        await queryClient.invalidateQueries('steps');
      },
    }
  );

  const {
    data: companyInfo,
    isLoading: isLoadingCompanyInfo,
    refetch: fetchCompanyInfo,
  } = useQuery<CompanyInfo>(
    ['companyInfo'],
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/info`,
        false
      ),
    {
      staleTime: 0,
      onSuccess: (data) => {
        setShowAlertMessageGetCompanyInfo(false);
        if (data.businessType) {
          setBusinessType(businessTypes[data.businessType - 1]);
        }
        if (data.firstName) {
          setFirstName(data.firstName);
        }
        if (data.lastName) {
          setLastName(data.lastName);
        }
        if (data?.issueBankAccount !== null && data?.issueBankAccount !== undefined) {
          setIsMyBusinessAccount(data?.issueBankAccount);
        }
      },
      onError: (err: any) => {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error fetching company info'), {
          extra: { error: JSON.stringify(err) },
        });
        setShowAlertMessageGetCompanyInfo(true);
      },
      enabled: !isLoading,
    }
  );

  const [hopRetries, setHopRetries] = useState(1);

  const {
    refetch: fetchHop,
    data: hopUrls,
    isLoading: isFetchingHop,
  } = useQuery<HOPUrls>(
    ['hopUrls'],
    () => apiFetch(`${process.env.REACT_APP_API_PAYMENTS}v1/payments/account/get-onboarding-link`),
    {
      initialData: {} as HOPUrls,
      staleTime: Infinity,
      enabled: false,
      onSuccess(data: HOPUrls) {
        setHopRetries(1);
        setShowAlertMessageLoadHopUrl(false);
        const hopUrl = data.Url;
        setKycUrl(hopUrl);
        if (isPOSApp) window.location.href = hopUrl;
        else {
          window.open(hopUrl, '_blank');
        }
        setPrepStage(PrepStage.KYC_IN_PROGRESS);
        setLoadingHop(false);
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        if (hopRetries < 3) {
          setHopRetries((prevRetries) => prevRetries + 1);
          setTimeout(fetchHop, Math.min(5000 * 2 ** hopRetries, 30000));
        } else {
          setHopRetries(1);
          Sentry.captureException(new Error('Error fetching HOP urls'), {
            extra: { error: JSON.stringify(err) },
          });
          setShowAlertMessageLoadHopUrl(true);
          setPrepStage(
            helpScreenAvailable ? PrepStage.PREPARE_KYC : PrepStage.SELECT_BUSINESS_TYPE
          );
          setLoadingHop(false);
        }
      },
    }
  );

  const loadHop = () => {
    setLoadingHop(true);
    return fetchHop();
  };

  const { isLoading: isUpdatingCompanyInfo, mutate: updateCompanyInfo } = useMutation(
    (newInfo: CompanyInfo) =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/info`,
        false,
        newInfo,
        'PUT'
      ),
    {
      async onSuccess() {
        if (location.pathname === '/payments-bank-account') {
          setShowAlertMessageUpdateCompanyInfo(false);
          setPrepStage(PrepStage.SELECT_BUSINESS_TYPE);
        } else {
          const nextStep = helpScreenAvailable ? PrepStage.PREPARE_KYC : PrepStage.CONFIRM_KYC;
          setShowAlertMessageUpdateCompanyInfo(false);
          setPrepStage(
            businessType === 'soleTrader' &&
              businessType &&
              isHop &&
              prepStage !== PrepStage.PROVIDE_SOLE_TRADER_NAME
              ? PrepStage.PROVIDE_SOLE_TRADER_NAME
              : nextStep
          );
        }
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error updating step skipped'), {
          extra: { error: JSON.stringify(err) },
        });
        setShowAlertMessageUpdateCompanyInfo(true);
      },
    }
  );

  useEffect(() => {
    if (currentStepIndex > -1 && !steps[currentStepIndex].startedAt) updateStepStarted('started');
  }, [currentStepIndex]);

  const saveBusinessType = useCallback(async () => {
    try {
      const type = businessTypes.indexOf(businessType) + 1;
      if (type === -1) {
        return false;
      }
      companyInfo.businessType = type;
      // sole trader company info must be updated after the user full name is provided, not before
      if (companyInfo.businessType !== 1 || isPrelim) updateCompanyInfo(companyInfo);
      else defer(() => setPrepStage(PrepStage.PROVIDE_SOLE_TRADER_NAME));
      return true;
    } catch {
      return false;
    }
  }, [companyInfo, businessType]);

  const saveUserFullName = useCallback(async () => {
    try {
      companyInfo.firstName = firstName;
      companyInfo.lastName = lastName;
      companyInfo.businessType = businessTypes.indexOf(businessType) + 1;
      if (companyInfo.businessType === -1 || !companyInfo.firstName) {
        return false;
      }
      updateCompanyInfo(companyInfo);
      return true;
    } catch {
      return false;
    }
  }, [companyInfo, businessType, firstName, lastName]);

  const saveIsMyBusinessAccount = useCallback(async () => {
    try {
      companyInfo.issueBankAccount = isMyBusinessAccount;
      updateCompanyInfo(companyInfo);
      return true;
    } catch {
      return false;
    }
  }, [companyInfo, isMyBusinessAccount]);

  const completeStep = useCallback(async () => {
    let updatedStep = null;
    if (location.pathname === '/payments-bank-account') {
      if (isMyBusinessAccount !== null) {
        const result = await saveIsMyBusinessAccount();
        if (!result) {
          updatedStep = {
            error: t('errors.errorUpdatingBusinessInfo'),
          };
        } else {
          updatedStep = {
            error: '',
            completable: true,
          };
        }
      } else {
        updatedStep = {
          error: t(`stepConfigs.${config.errorMessage}`),
        };
      }

      setCurrentStep(updatedStep);
    }

    if ((currentStepIndex in steps && steps[currentStepIndex].completedAt) || finishedAllSteps)
      goToNextStep();
    else if (currentStep.completable || updatedStep?.completable) {
      updateStepCompleted('completed');
    } else if (location.pathname === '/payments-verification' && (kycUrl || isHop || kycUrlError)) {
      if (!businessType) {
        updateCurrentStep({
          error: t('errors.errorSelectBusinessType'),
        });
        return;
      }
      if (prepStage === PrepStage.SELECT_BUSINESS_TYPE) {
        const result = await saveBusinessType();
        if (!result) {
          updateCurrentStep({
            error: t('errors.errorUpdatingBusinessInfo'),
          });
          return;
        }
      }
      if (prepStage === PrepStage.PROVIDE_SOLE_TRADER_NAME) {
        if (!firstName || !lastName) {
          updateCurrentStep({
            error: t('errors.errorProvideYourName'),
          });
          return;
        }
        const result = await saveUserFullName();
        if (!result) {
          updateCurrentStep({
            error: t('errors.errorUpdatingBusinessInfo'),
          });
          return;
        }
        updateCurrentStep({ error: '', completable: false });
      }
      if (prepStage === PrepStage.CONFIRM_KYC) {
        if (isHop) {
          fetchHop();
        } else if (kycUrl && isPOSApp) {
          window.location.href = kycUrl;
        } else if (kycUrl) window.open(kycUrl);
      }
      if (prepStage !== PrepStage.CONFIRM_KYC_COMPLETE) {
        setPrepStage((prevStage: PrepStage) => {
          switch (prevStage) {
            case PrepStage.SELECT_BUSINESS_TYPE:
              return PrepStage.SELECT_BUSINESS_TYPE; /* don't change it here, it will move forward once company type is saved */
            case PrepStage.PROVIDE_SOLE_TRADER_NAME:
              return helpScreenAvailable ? PrepStage.PREPARE_KYC : PrepStage.CONFIRM_KYC;
            case PrepStage.PREPARE_KYC:
              return PrepStage.CONFIRM_KYC;
            case PrepStage.CONFIRM_KYC:
              return isHop
                ? PrepStage.LOADING_HOP_URL
                : PrepStage.KYC_IN_PROGRESS; /* if we have to fetch HOP URL it will be changed then */
            case PrepStage.LOADING_HOP_URL:
              return PrepStage.LOADING_HOP_URL;
            case PrepStage.KYC_IN_PROGRESS:
              return isPOSApp ? PrepStage.KYC_IN_PROGRESS : PrepStage.CONFIRM_KYC_COMPLETE;
            default:
              return PrepStage.SELECT_BUSINESS_TYPE;
          }
        });
      }
      if (prepStage === PrepStage.CONFIRM_KYC_COMPLETE) {
        // reload the page as a simple way to check for KYC status
        window.location.reload();
      }
      if (kycUrlError)
        updateCurrentStep({
          error: t('errors.errorNextStepKycUrl'),
        });
    } else {
      updateCurrentStep({
        error: t(`stepConfigs.${config.errorMessage}`),
      });
    }
  }, [
    steps,
    currentStep,
    location,
    prepStage,
    kycUrl,
    kycUrlError,
    currentStepIndex,
    finishedAllSteps,
    updateStepCompleted,
    setPrepStage,
    updateCurrentStep,
    location,
  ]);

  const skipStep = () => {
    if (steps[currentStepIndex].completedAt || steps[currentStepIndex].skippedAt) goToNextStep();
    else updateStepSkipped('skipped');
  };

  useEffect(() => {
    if (steps.length === 0) {
      if (location.pathname === '/') setIsFirstLoad(true);
      return;
    }

    if (isToMoveToNextStep) {
      setIsToMoveToNextStep(false);
      goToNextStep();
    } else {
      if (
        (finishedAllSteps && location.pathname === '/payments-complete') ||
        (allStepsCompleted && location.pathname === '/complete')
      ) {
        return;
      }
      if (steps.findIndex((step) => !step.completedAt) === -1) {
        setNoMoreSteps(steps.findIndex((step) => !step.completedAt) === -1);
        setAllStepsCompleted(true);
        if (location.pathname !== '/complete') navigateAndReset('/complete');
        return;
      }
      setFinishedAllSteps(false);
      setAllStepsCompleted(false);
      const newStep = steps.filter((step) => location.pathname.includes(`/${step.stepKey}`))?.[0];
      const index = steps.indexOf(newStep);

      if (index > -1) {
        setIsLastStep(steps[index].stepKey === steps[steps.length - 1].stepKey);
        setIsFirstStep(steps[index].stepKey === steps[0].stepKey);
      }

      if (
        index === 0 ||
        (index > 0 && (steps[index - 1].completedAt || steps[index - 1].skippedAt))
      ) {
        if (updatedStartAt) setUpdatedStartAt(false);
        else setNewStep(index);
      } else if (location.pathname === '/' && !isFirstLoad) {
        navigateAndReset(`/${steps[0].stepKey}`, true);
      } else {
        if (isFirstLoad) setIsFirstLoad(false);
        const firstIncompleteAndNotSkippedStep = steps.findIndex(
          (step) => !step.completedAt && !step.skippedAt
        );
        const firstStepIndex =
          firstIncompleteAndNotSkippedStep > -1
            ? firstIncompleteAndNotSkippedStep
            : steps.findIndex((step) => !step.completedAt && step.skippedAt);
        if (firstStepIndex > -1) navigateAndReset(`/${steps[firstStepIndex].stepKey}`, true);
        else navigateAndReset(`/${steps[0].stepKey}`, true);
      }
    }
  }, [steps, location.pathname]);

  const unauthorized = (error as any)?.code === 401;

  useEffect(() => {
    if (location.pathname === '/payments-verification') {
      fetchCompanyInfo();
    }
  }, [location.pathname, fetchCompanyInfo]);

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('location', 'StepController');
      }}
      fallback={<Fallback />}
    >
      {allStepsCompleted && <Outlet context={{ noMoreSteps, isPathComplete: true }} />}
      {(isLoading || isLoadingUserCountry || loadingHop || currentStepIndex < 0) &&
        !allStepsCompleted &&
        !(isError && !unauthorized) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '96px',
            }}
          >
            <CircularProgress
              aria-label="Loading Data"
              aria-live="polite"
              aria-busy={
                (isLoading || isLoadingUserCountry || loadingHop || currentStepIndex < 0) &&
                !allStepsCompleted &&
                !(isError && !unauthorized)
              }
              data-testid="loading-icon"
              sx={{ color: 'text.secondary' }}
            />
          </Box>
        )}
      {!isLoading && !loadingHop && isError && !unauthorized && (
        <ErrorLoadingData action={fetchData} />
      )}
      {!isLoading &&
        !loadingHop &&
        currentStepIndex > -1 &&
        !allStepsCompleted &&
        !unauthorized &&
        !isLoadingUserCountry && (
          <>
            {isError && <ErrorLoadingData action={fetchData} />}
            {!isError && (
              <>
                {!noStepsDevice && (
                  <Stepper
                    nonLinear
                    alternativeLabel={isSmallDevice}
                    activeStep={currentStepIndex}
                    sx={{
                      justifyContent: 'space-between',
                      marginTop: { xs: 4, md: 6 },
                      marginBottom: { xs: 4, md: 6 },
                    }}
                  >
                    {steps.map((step, index) => (
                      <StepperStep
                        key={`step-${step.stepKey}`}
                        completed={step.completedAt != null}
                        sx={{
                          ...(index === 0 && {
                            paddingLeft: `${!noMoreSteps && !step.startedAt ? 0 : '8px'}`,
                            paddingRight: `${!noMoreSteps && step.startedAt ? 0 : '8px'}`,
                          }),

                          ...(index === steps.length - 1 && {
                            paddingLeft: `${!noMoreSteps && step.startedAt ? 0 : '8px'}`,
                            paddingRight: `${!noMoreSteps && !step.startedAt ? 0 : '8px'}`,
                          }),

                          ':not(.Mui-completed) svg': {
                            color: `${
                              currentStepIndex === index
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary
                            }`,
                          },
                          ':not(.Mui-completed) span': {
                            color: 'text.secondary',
                          },
                        }}
                      >
                        {!noMoreSteps && step.startedAt ? (
                          <StepButton
                            sx={{
                              ...(index === 0 && {
                                paddingLeft: '8px',
                                paddingRight: '24px',
                              }),

                              ...(index === steps.length - 1 && {
                                paddingLeft: '24px',
                                paddingRight: '8px',
                              }),
                            }}
                            onClick={() => {
                              navigateAndReset(`/${step.stepKey}`);
                            }}
                          >
                            {t(`stepConfigs.${getStepConfig(step).title}`)}
                          </StepButton>
                        ) : (
                          <StepLabel>{t(`stepConfigs.${getStepConfig(step).title}`)}</StepLabel>
                        )}
                      </StepperStep>
                    ))}
                  </Stepper>
                )}

                {finishedAllSteps && (
                  <MainHeader
                    title={t(`screens.complete.${noMoreSteps ? 'allSet' : 'nearlyDone'}`)}
                    styles={{
                      marginTop: { xs: 0, sm: 4, md: 6 },
                      marginBottom: { xs: 4, sm: 5 },
                    }}
                    theme={theme}
                  />
                )}
                {!finishedAllSteps && (
                  <MainHeader
                    title={t(`stepConfigs.${config.header}`)}
                    styles={{
                      marginTop: { xs: 0, sm: 4, md: 6 },
                      marginBottom: { xs: 4, sm: 5 },
                    }}
                    theme={theme}
                  >
                    <Typography>{t(`stepConfigs.${config.description}`)}</Typography>
                  </MainHeader>
                )}

                {showAlertMessageLoadHopUrl && (
                  <ServerAlertMessage
                    message={t(`errors.errorLoadingKycUrls`)}
                    isLoading={isFetchingHop}
                    refetch={() => {
                      setPrepStage(PrepStage.LOADING_HOP_URL);
                      fetchHop();
                    }}
                    alertRef={messageRef}
                  />
                )}
                <Box>
                  {currentStep.error && (
                    <AlertMessage
                      type="error"
                      styles={{
                        marginY: 2,
                      }}
                      alertRef={messageRef}
                      action={() => updateCurrentStep({ error: '' })}
                      iconAlignment="baseline"
                      locale={locale}
                      theme={theme}
                    >
                      {currentStep.error}
                    </AlertMessage>
                  )}
                  {location.pathname === 'payments-verification' && (
                    <>
                      {showAlertMessageGetCompanyInfo && (
                        <ServerAlertMessage
                          message={t(`errors.errorLoadingCompanyInfo`)}
                          isLoading={isLoadingCompanyInfo}
                          refetch={fetchCompanyInfo}
                          alertRef={messageRef}
                        />
                      )}
                      {showAlertMessageUpdateCompanyInfo && (
                        <ServerAlertMessage
                          message={t(`errors.errorUpdatingBusinessInfo`)}
                          isLoading={isUpdatingCompanyInfo}
                          refetch={!firstName ? saveBusinessType : saveUserFullName}
                          alertRef={messageRef}
                        />
                      )}
                    </>
                  )}
                  {location.pathname === '/payments-complete' ? (
                    <Outlet
                      context={{
                        noMoreSteps,
                        isPathComplete: false,
                        updateCurrentStep,
                        currentStep,
                        kycUrl,
                        setKycUrl,
                        businessType,
                        setBusinessType,
                        setPrepStage,
                        setKycUrlError,
                        prepStage,
                        completeStep,
                        setFirstName,
                        setLastName,
                        loadHop,
                        isLoadingHop: loadingHop,
                        isErrorHop: showAlertMessageLoadHopUrl,
                        firstName,
                        lastName,
                      }}
                    />
                  ) : (
                    <Outlet
                      context={{
                        updateCurrentStep,
                        currentStep,
                        kycUrl,
                        setKycUrl,
                        businessType,
                        setBusinessType,
                        setPrepStage,
                        setKycUrlError,
                        prepStage,
                        completeStep,
                        setFirstName,
                        setLastName,
                        loadHop,
                        isLoadingHop: loadingHop,
                        isErrorHop: showAlertMessageLoadHopUrl,
                        firstName,
                        lastName,
                        isMyBusinessAccount,
                        setIsMyBusinessAccount,
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 2,
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  {noStepsDevice && (
                    <MobileStepper
                      variant="progress"
                      LinearProgressProps={{ 'aria-label': 'Steps progress bar' }}
                      steps={steps.length}
                      position="static"
                      activeStep={currentStepIndex}
                      sx={{
                        flexGrow: 1,
                        padding: 0,
                        '.MuiMobileStepper-progress': { width: '100%' },
                      }}
                      backButton={null}
                      nextButton={null}
                    />
                  )}
                  <Box
                    sx={{
                      ...(!noStepsDevice && {
                        borderTop: `1px ${
                          colorMode === 'light'
                            ? theme.palette.grey['12p']
                            : theme.palette.grey['23p']
                        } solid`,
                      }),
                      paddingY: 1,
                      paddingX: { xs: 2, sm: 3 },
                    }}
                  >
                    <Container
                      sx={{
                        paddingX: { xs: 0, lg: 3 },
                        display: 'flex',
                        justifyContent: `${
                          !isFirstStep && !noMoreSteps ? 'space-between' : 'flex-end'
                        }`,
                      }}
                    >
                      {/* POS App is not compatible with some modernities like fit-content, we use an old Chrome... */}
                      {!isFirstStep && !noMoreSteps && (
                        <Button
                          color="primary"
                          variant="outlined"
                          disableElevation
                          onClick={goToPrevStep}
                          sx={{
                            height: isPOSApp ? '48px' : 'fit-content',
                            paddingY: { xs: '5px', sm: '9px' },
                            paddingX: { xs: 2, sm: 3 },
                          }}
                        >
                          {t('actions.previous')}
                        </Button>
                      )}
                      <Box>
                        {!config.required && !finishedAllSteps && (
                          <Button
                            color="primary"
                            disableElevation
                            onClick={() =>
                              !isUpdatingStepSkipped && !isUpdatingStepCompleted && skipStep()
                            }
                            sx={{
                              paddingY: { xs: '6px', sm: '10px' },
                              paddingX: { xs: 2, sm: 3 },
                              marginRight: 1,
                            }}
                          >
                            {isUpdatingStepSkipped && (
                              <>
                                <CircularProgress
                                  color="inherit"
                                  size="20px"
                                  sx={{ marginRight: '8px' }}
                                />
                                {t('actions.skip')}
                              </>
                            )}
                            {!isUpdatingStepSkipped && t('actions.skip')}
                          </Button>
                        )}
                        {(location.pathname !== '/payments-verification' ||
                          (location.pathname === '/payments-verification' &&
                            (!kycUrl ||
                              (kycUrl && process.env.REACT_APP_VERIFICATION_ON === 'true')))) && (
                          <Button
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() =>
                              !isUpdatingStepCompleted &&
                              !isUpdatingCompanyInfo &&
                              !isUpdatingStepSkipped &&
                              completeStep()
                            }
                            sx={{
                              paddingY: { xs: '6px', sm: '10px' },
                              paddingX: { xs: 2, sm: 3 },
                              height: isPOSApp ? '48px' : 'fit-content',
                            }}
                          >
                            {(isUpdatingStepCompleted || isUpdatingCompanyInfo) && (
                              <>
                                <CircularProgress
                                  aria-label="Updating completed step"
                                  aria-live="polite"
                                  aria-busy={isUpdatingStepCompleted || isUpdatingCompanyInfo}
                                  data-testid="loading-icon"
                                  color="inherit"
                                  size="20px"
                                  sx={{ marginRight: '8px' }}
                                />
                                {(!finishedAllSteps && t('actions.next')) ||
                                  (finishedAllSteps &&
                                    t(`${noMoreSteps ? 'actions.done' : 'actions.gotIt'}`))}
                              </>
                            )}
                            {!isUpdatingStepCompleted &&
                              !isUpdatingCompanyInfo &&
                              ((!finishedAllSteps && t('actions.next')) ||
                                (finishedAllSteps &&
                                  t(`${noMoreSteps ? 'actions.done' : 'actions.gotIt'}`)))}
                          </Button>
                        )}
                      </Box>
                    </Container>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
    </Sentry.ErrorBoundary>
  );
};

type StepContextType = {
  updateCurrentStep: (data: any) => void;
  currentStep: CurrentStep;
  setKycUrl: (val: string) => void;
  setKycUrlError: (val: boolean) => void;
  businessType: string;
  setBusinessType: (businessType: string) => void;
  prepStage: PrepStage;
  setPrepStage: (val: PrepStage) => void;
  completeStep: () => void;
  kycUrl: string;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  firstName?: string;
  lastName?: string;
  loadHop?: () => Promise<unknown>;
  isLoadingHop?: boolean;
  isErrorHop?: boolean;
  isMyBusinessAccount?: boolean;
  setIsMyBusinessAccount?: (val: boolean) => void;
};
export const useSteps = () => useOutletContext<StepContextType>();

type CompleteType = { noMoreSteps: boolean; isPathComplete: boolean };
export const useComplete = () => useOutletContext<CompleteType>();

export default StepController;
