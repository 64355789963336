import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth } from 'oidc-react';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import enLocale from 'date-fns/locale/en-GB';
import { useCookies } from 'react-cookie';
import { AppsSection } from '@eposnow/ui-core';
import { apiFetchDoNotUse as apiFetchFn } from '../api/fetch';
import { UIContext } from './UIContext';
import localesMap from '../i18n/localesMap.json';
import { SubuserRightsResponse, UserAccessRights, User } from '../types';
import ErrorLoadingData from '../components/ErrorLoadingData';
import AuthenticatingSpinner from '../components/AuthenticatingSpinner';

const DEFAULT_LOCALE = 'en-GB';

export type UserRole = {
  RoleId: string;
  RoleName: string;
  Description: string;
};

export type AppsMenuResponse = {
  baseUrl: string;
  items: AppsSection[][];
}

export const userRoles = {
  moduleStandalone: '_EposStandalonePayments',
  moduleNewOnboardingUrl: '_EposNowNewOnboardingURL'
};

/* eslint-enable @typescript-eslint/no-empty-function */
const UserContext = React.createContext({
  apiFetch: apiFetchFn,
  locale: DEFAULT_LOCALE,
  localeFns: enLocale,
  userCountry: undefined,
  isLoadingUserCountry: true,
  user: undefined,
  retryUserCountry: () => {
    /* no op */
  },
  userModules: [] as UserRole[] | undefined,
  userAccessRights: {} as UserAccessRights,
  cdnUrl: "",
  appsMenu: [] as AppsSection[][],
  isLoadingUserApps: false,
  isErrorUserApps: false,
  refetchUserApps: () => { /* no op */ },
});

export const isInRole = (userModules: UserRole[] | undefined, module: string) =>
  userModules?.filter((el) => el.RoleName === module).length > 0;

const UserContextProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState<string>(DEFAULT_LOCALE);
  const [localeFns, setLocaleFns] = useState<Locale>(enLocale);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [userCountry, setUserCountry] = useState('');
  const [cookies, setCookie] = useCookies(['useDarkTheme', 'disableAnimation']);
  const [cdnUrl, setCdnUrl] = useState("");
  const [appsMenu, setAppsMenu] = useState<AppsSection[][]>([]);

  const { setDisableAnimationFromBoolean, setColorModeFromBoolean } =
    useContext(UIContext);

  const [searchParams, _setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('disableNav'))
      sessionStorage.setItem('disableNav', searchParams.get('disableNav'));
  }, []);

  const apiFetch = (
    url: string,
    v4Errors = true,
    body = {},
    method = 'GET',
    authorization = '',
    retry = true,
    plain = false,
    extraHeaders: Record<string, string> = {}
  ) =>
    apiFetchFn(url, v4Errors, body, method, `Bearer ${auth?.userData?.access_token}`, retry, plain, extraHeaders);

  const importLocaleFile = async (lang: string) => {
    const localeToSet = await import(`date-fns/locale/${lang}/index.js`);
    setLocaleFns(localeToSet.default);
  };

  const changeLanguage = (language: string) => {
    let lang = language;
    let dateLang = language;
    if (localesMap.locales[lang]) {
      dateLang = localesMap.locales[lang];
    } else {
      lang = DEFAULT_LOCALE;
      dateLang = DEFAULT_LOCALE;
    }

    setLocale(lang);
    i18n.changeLanguage(lang).then(() => {
      importLocaleFile(dateLang).then();
    });
    document.documentElement.setAttribute('lang', lang);
  };

  const {
    refetch: fetchData,
    data: user,
    isLoading,
    isError,
    error
  } = useQuery(
    'user',
    () =>
      apiFetch(`${process.env.REACT_APP_API_IDENTITY}v1/user`),
    {
      enabled: queryEnabled,
      staleTime: 0,
      onSuccess: (data: User) => {

        changeLanguage(data.locale);
        Sentry.setTag('ui_language', data.locale);
        Sentry.setTag('user_id', data.id);
      },
    }
  );

  const { refetch: retryUserCountry, isLoading: isLoadingUserCountry } = useQuery(
    'territory',
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_PAYMENTS}v1/payments/company-info/get-company-region`,
        true,
        {},
        'GET',
        '',
        true,
        true
      ),
    {
      enabled: queryEnabled,
      onSuccess: (data: string) => {
        setUserCountry(data);
      },
    }
  );

  const { data: userModules } = useQuery(
    'userModules',
    () => apiFetch(`${process.env.REACT_APP_API_V4}user/modules`),
    {
      enabled: queryEnabled,
      onSuccess: (data: UserRole[]) => {
        Sentry.setTag('standalone_user', isInRole(data, userRoles.moduleStandalone));
      },
    }
  );

  const { isLoading: isLoadingUserApps, refetch: refetchUserApps, isError: isErrorUserApps } = useQuery(
    'userApps',
    () => apiFetch(`${process.env.REACT_APP_API_IDENTITY}v1/apps`, true, {}, 'GET', '', true, false, {
      "Accept-Language": user.locale
    }),
    {
      enabled: queryEnabled && !!user?.locale,
      onSuccess: (data: AppsMenuResponse) => {
        setCdnUrl(data.baseUrl);
        setAppsMenu(data.items);
      },
    }
  );

  const { data: uiPreferences } = useQuery(
    'uiPreferences',
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_ORGANISATIONS}v1/users/${auth?.userData?.profile.sub}/ui-preferences`
      ),
    {
      enabled: queryEnabled && auth?.userData?.profile.sub != null,
      staleTime: 0,
      onSuccess: (data: { useDarkTheme: boolean; disableAnimation: boolean }) => {
        setCookie("useDarkTheme", data?.useDarkTheme);
        setCookie("disableAnimation", data?.disableAnimation);
        setColorModeFromBoolean(data.useDarkTheme);
        setDisableAnimationFromBoolean(data.disableAnimation);
      },
    }
  );

  useEffect(() => {
    setColorModeFromBoolean(uiPreferences?.useDarkTheme || cookies.useDarkTheme);
    setDisableAnimationFromBoolean(uiPreferences?.disableAnimation || cookies.disableAnimation);
  }, [uiPreferences]);

  useEffect(() => {
    let timer;
    if (auth.isLoading) {
      timer = setTimeout(() => {
        if (auth.isLoading) {
          window.location.href = '/';
        }
      }, 3000);
      return () => clearTimeout(timer);
    }

    setQueryEnabled(true);
    return () => clearTimeout(timer);
  }, [auth.isLoading]);
  const { data: userRights, isLoading: isLoadingAccessRights } = useQuery(
    'userRights',
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_V4}subuser/${auth?.userData?.profile.sub}`
      ),
    {
      enabled: !auth?.isLoading && auth?.userData?.profile?.sub?.toLowerCase() !== (auth?.userData?.profile?.parent_guid as string)?.toLowerCase(),
    }
  );

  const formatAccessRights = useCallback((isSublogin: boolean, accessRights: SubuserRightsResponse | undefined): UserAccessRights => {
    if (!isSublogin) {
      return {
        Billing: true,
        LocationAreaID: null,
        Setup: true,
        Management: true,
        PurchaseOrderCreate: true,
        PurchaseOrderEditCancel: true,
        PurchaseOrderReceive: true,
        Product: true,
        Reporting: true,
        Margin: true,
        Till: true,
        WebIntegration: true,
        Apps: true,
        GlobalCustomer: true,
        Franchise: true,
      }
    }

    if (!accessRights) {
      return {}
    }

    return {
      Billing: accessRights.BillingRights || false,
      LocationAreaID: accessRights.AccessRights.LocationAreaID,
      Setup: accessRights.AccessRights.Setup || false,
      Management: accessRights.AccessRights.Management || false,
      PurchaseOrderCreate: accessRights.AccessRights.PurchaseOrder.Create || false,
      PurchaseOrderEditCancel: accessRights.AccessRights.PurchaseOrder.EditCancel || false,
      PurchaseOrderReceive: accessRights.AccessRights.PurchaseOrder.Receive || false,
      Product: accessRights.AccessRights.Product || false,
      Reporting: accessRights.AccessRights.Reporting || false,
      Margin: accessRights.AccessRights.Margin || false,
      Till: accessRights.AccessRights.Till || false,
      WebIntegration: accessRights.AccessRights.WebIntegration || false,
      Apps: accessRights.AccessRights.Apps || false,
      GlobalCustomer: accessRights.AccessRights.GlobalCustomer || false,
      Franchise: accessRights.AccessRights.Franchise || false,
    }

  }, [])

  if (window.location.pathname.includes('logged-out')) return null;


  const userAccessRights = formatAccessRights(auth?.userData?.profile?.sub?.toLowerCase() !== (auth?.userData?.profile?.parent_guid as string)?.toLowerCase(), userRights);

  const unauthorized = (error as any)?.code === 401;

  if (auth && auth.userData) {
    if (isError && unauthorized) return <AuthenticatingSpinner />;
    if (isError) return <ErrorLoadingData action={fetchData} />;
    if (isLoading || !user)
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '96px',
          }}
        >
          <CircularProgress
            aria-label="Loading User Data"
            aria-live="polite"
            aria-busy={ isLoading || !user }
            data-testid="loading-icon"
            sx={{ color: 'text.secondary' }}
          />
        </Box>
      );
    return (
      <UserContext.Provider
        value={{
          apiFetch,
          locale,
          localeFns,
          user,
          userCountry,
          retryUserCountry,
          isLoadingUserCountry,
          userModules,
          userAccessRights,
          isLoadingUserApps,
          cdnUrl,
          appsMenu,
          isErrorUserApps,
          refetchUserApps,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }

  if (auth.isLoading) {
    return <AuthenticatingSpinner />;
  }

  return null;
};

export { UserContext, UserContextProvider };
