import React, { useContext, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Card, CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import KYCNotStarted from './KYCNotStarted';
import { KYCResponseType, PaymentsUrls } from '../../types';
import ErrorLoadingData from '../../components/ErrorLoadingData';
import { useSteps } from '../../components/StepController';
import KYCStarted from './KYCStarted';
import ServerAlertMessage from '../../components/ServerAlertMessage';
import { UserContext } from '../../context/UserContext';
import { useUserCountry } from '../../helpers/useCountries';
import checkKYCPassed from './kycHelpers';

const Verification = () => {
  const { t } = useTranslation();
  const { apiFetch } = useContext(UserContext);
  const {
    isError: userCountryError,
    retryUserCountry,
    isPrelim,
    isLoading: isLoadingUserCountry,
  } = useUserCountry();
  const [isToSaveUrlInSteps, setIsToSaveUrlInSteps] = useState(false);

  document.title = t('app.pageTitle', { name: t('nav.verification') });
  const stepContext = useSteps();
  const messageRef = useRef<null | HTMLElement>(null);

  const resetKycUrl = () => {
    stepContext?.setKycUrlError(false);
    stepContext?.setKycUrl('');
    if (stepContext?.updateCurrentStep) stepContext.updateCurrentStep({ error: '' });
  };

  const placeKycUrl = (val: string) => {
    stepContext?.setKycUrl(val);
    stepContext?.setKycUrlError(false);
  };

  const enableKycUrlError = () => {
    stepContext?.setKycUrl('');
    stepContext?.setKycUrlError(true);
  };

  const {
    refetch: fetchUrls,
    data: kycUrls,
    isFetching: isFetchingPaymentsUrls,
    isError: isErrorKycUrls,
    error: errorKycUrls,
  } = useQuery<PaymentsUrls>(
    ['paymentsUrls'],
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_PAYMENTS}v1/payments/banking/account/get-payments-urls`
      ),
    {
      initialData: {} as PaymentsUrls,
      enabled: false,
      staleTime: Infinity,
      onSuccess(data: PaymentsUrls) {
        if (isToSaveUrlInSteps) {
          if (data.OnboardingUrl) placeKycUrl(data.OnboardingUrl);
          else enableKycUrlError();
        } else resetKycUrl();
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error fetching payments urls'), {
          extra: { error: JSON.stringify(err) },
        });
        enableKycUrlError();
      },
    }
  );

  const getKycStatus = (data: KYCResponseType) => {
    if (data.status === 204) return 'not started (204)';
    if (checkKYCPassed(data)) return 'passed';
    return 'pending (200 not passed)';
  };

  const {
    refetch: fetchData,
    data: kycResponse,
    isLoading: isLoadingCapabilities,
    isError: isErrorCapabilities,
    error: capabilitiesError,
  } = useQuery<KYCResponseType>(
    ['kyc'],
    () => apiFetch(`${process.env.REACT_APP_API_PAYMENTS}v1/payments/account/get-capabilities`),
    {
      onSuccess(data: KYCResponseType) {
        Sentry.addBreadcrumb({
          type: 'info',
          category: 'sentry.transaction',
          message: `KYC Status ${getKycStatus(data)}`,
          level: 'info',
        });
        setIsToSaveUrlInSteps(data.status === 204);
        if (data.status !== 204) resetKycUrl();
        if (!kycUrls?.OnboardingUrl && isPrelim) fetchUrls();
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error fetching KYC status'), {
          extra: { error: JSON.stringify(err) },
        });
        resetKycUrl();
      },
    }
  );

  const unauthorized =
    (capabilitiesError as any)?.code === 401 || (errorKycUrls as any)?.code === 401;

  return isLoadingCapabilities || isFetchingPaymentsUrls || isLoadingUserCountry || unauthorized ? (
    <Card
      variant="outlined"
      sx={{
        marginBottom: 2,
        p: { xs: 2, sm: 3 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '96px',
        }}
      >
        <CircularProgress
          aria-label="Loading Data"
          aria-live="polite"
          aria-busy={isLoadingCapabilities || unauthorized}
          data-testid="loading-icon"
          sx={{ color: 'text.secondary' }}
        />
      </Box>
    </Card>
  ) : (
    <>
      {isErrorKycUrls && (
        <ServerAlertMessage
          message={t(`errors.errorLoadingKycUrls`)}
          isLoading={isFetchingPaymentsUrls}
          refetch={fetchUrls}
          alertRef={messageRef}
        />
      )}
      {(isErrorCapabilities || userCountryError) &&
        !isLoadingCapabilities &&
        !isFetchingPaymentsUrls && (
          <Card
            variant="outlined"
            sx={{
              marginBottom: 2,
              p: { xs: 2, sm: 3 },
            }}
          >
            <ErrorLoadingData
              action={() => {
                fetchData();
                retryUserCountry();
              }}
            />
          </Card>
        )}
      {!isErrorCapabilities &&
        !userCountryError &&
        !isLoadingCapabilities &&
        !isFetchingPaymentsUrls &&
        (kycResponse?.status !== 204 ? <KYCStarted /> : <KYCNotStarted />)}
    </>
  );
};

export default Verification;
