import React, {useMemo, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {PaletteMode, useMediaQuery} from '@mui/material';
import {DeviceModelDetector} from '@eposnow/custom-browser-utilities';
import {LG_WIDTH, MD_WIDTH, SM_WIDTH} from '../constants';

export const getColorMode = (): PaletteMode =>
  (sessionStorage.getItem('colorMode') as PaletteMode) || ('light' as PaletteMode);

export const getDisableAnimation = (): boolean =>
  sessionStorage.getItem('disableAnimation') === 'true';

/* eslint-disable @typescript-eslint/no-empty-function */
const UIContext = React.createContext({
  isMobile: false,
  isTablet: false,
  isSmallDevice: false,
  isLargeDevice: false,
  noStepsDevice: false,
  iOS: false,
  isMediumDevice: true,
  isEposHardware: false,
  isPOSApp: false,
  colorMode: 'light' as PaletteMode,
  setColorModeFromBoolean: (_newColorMode: boolean) => {},
  disableAnimation: false,
  setDisableAnimationFromBoolean: (_newDisableAnimation: boolean) => {},
});
/* eslint-enable @typescript-eslint/no-empty-function */

const UIContextProvider = ({ children }: { children: JSX.Element }) => {
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const dimensionsTheme = useTheme();
  const isSmallDevice = useMediaQuery(dimensionsTheme.breakpoints.down(400));
  const isMobile = useMediaQuery(dimensionsTheme.breakpoints.down(SM_WIDTH));
  const isTablet = useMediaQuery(dimensionsTheme.breakpoints.between(SM_WIDTH, MD_WIDTH));
  const isMediumDevice = useMediaQuery(dimensionsTheme.breakpoints.up(MD_WIDTH));
  const isLargeDevice = useMediaQuery(dimensionsTheme.breakpoints.up(LG_WIDTH));
  const noStepsDevice = useMediaQuery(dimensionsTheme.breakpoints.down(700));

  const [disableAnimation, setDisableAnimation] = useState(getDisableAnimation());
  const [colorMode, setColorMode] = useState<PaletteMode>(getColorMode());

  const isPOSApp = useMemo(() => navigator.userAgent.includes("EposAndroidApp") || navigator.userAgent.includes("EposIOSApp") || navigator.userAgent.includes("POS"), []);

  const isEposHardware = useMemo(
    () => isPOSApp ||
      DeviceModelDetector.instance().isSmartPosDevice() ||
      DeviceModelDetector.instance().isDesktopDevice() ||
      DeviceModelDetector.instance().isMobileDevice(),
    []
  );

  const setColorModeFromBoolean = (isDarkTheme: boolean) => {
    const newColorMode = isDarkTheme ? 'dark' : 'light';
    sessionStorage.setItem('colorMode', newColorMode);
    setColorMode(newColorMode);
  };

  const setDisableAnimationFromBoolean = (isDisableAnimation: boolean) => {
    sessionStorage.setItem('disableAnimation', JSON.stringify(isDisableAnimation));
    setDisableAnimation(isDisableAnimation);
  };

  const contextValue = {
    isMobile,
    isTablet,
    isSmallDevice,
    isLargeDevice,
    iOS,
    isMediumDevice,
    isEposHardware,
    isPOSApp,
    colorMode,
    setColorModeFromBoolean,
    disableAnimation,
    setDisableAnimationFromBoolean,
    noStepsDevice,
  };
  return <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>;
};

export { UIContext, UIContextProvider };
