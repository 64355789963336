import * as React from 'react';
import { useState } from 'react';
import { Box, Card, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSteps } from '../../../components/StepController';
import {
  bankStatement,
  companiesHouse,
  constitution,
  drivingLicenceBack,
  drivingLicenceFront,
  governmentId,
  openingLetter,
  passport,
  utr,
} from './assets';
import Markdown from '../../../components/Markdown';
import HelpPanel from './HelpPanel';
import { useUserCountry } from '../../../helpers/useCountries';
import { UIContext } from '../../../context/UIContext';

const PrepareForKYC = () => {
  const { t } = useTranslation();
  const stepContext = useSteps();
  const [helpPage, setHelpPage] = useState<string | undefined>(undefined);
  const { userCountry, isLoading: isLoadingUserCountry } = useUserCountry();
  const { colorMode } = React.useContext(UIContext);

  const Generic = `### ${t('screens.verification.kycNotStarted.verification')}\n\n${t(
    'screens.verification.kycPreparation.generic.description'
  )}`;

  const UKSoleTrader = `### ${t(
    'screens.verification.kycNotStarted.businessTypes.soleTrader'
  )}\n\n${t('screens.verification.kycPreparation.soleTrader.description')}\n\n> * ${t(
    'screens.verification.kycPreparation.requirements.governmentId'
  )}\n> * ${t('screens.verification.kycPreparation.requirements.bankStatement')}\n>\n> [${t(
    'screens.verification.kycPreparation.requirements.idMustMatch'
  )}](banner:warning)`;

  const AUSSoleTrader = `### ${t(
    'screens.verification.kycNotStarted.businessTypesAus.soleTrader'
  )}\n\n${t('screens.verification.kycNotStarted.businessTypesAus.soleTraderDescription')} ${t(
    'screens.verification.kycPreparation.requirements.youWillNeed'
  )}\n\n> * ${t('screens.verification.kycPreparation.requirementsAus.governmentId')}\n> * ${t(
    'screens.verification.kycPreparation.requirementsAus.bankStatement'
  )}\n>\n> [${t('screens.verification.kycPreparation.requirements.idMustMatch')}](banner:warning)`;
  const AUSLimited = `### ${t('screens.verification.kycNotStarted.businessTypesAus.ltd')}\n\n${t(
    'screens.verification.kycNotStarted.businessTypesAus.ltdDescription'
  )} ${t('screens.verification.kycPreparation.requirements.youWillNeed')}\n\n> * ${t(
    'screens.verification.kycPreparation.requirementsAus.companyRegistration'
  )}\n> * ${t('screens.verification.kycPreparation.requirementsAus.governmentIdDirector')}\n> * ${t(
    'screens.verification.kycPreparation.requirementsAus.businessBankStatement'
  )}\n>\n> [${t(
    'screens.verification.kycPreparation.requirementsAus.mustRegisterLtd'
  )}](banner:warning)`;
  const AUSPartnership = `### ${t(
    'screens.verification.kycNotStarted.businessTypesAus.partnership'
  )}\n\n${t('screens.verification.kycNotStarted.businessTypesAus.partnershipDescription')} ${t(
    'screens.verification.kycPreparation.requirements.youWillNeed'
  )}\n\n> * ${t(
    'screens.verification.kycPreparation.requirementsAus.companyRegistration'
  )}\n> * ${t(
    'screens.verification.kycPreparation.requirementsAus.governmentIdAuthorised'
  )}\n> * ${t(
    'screens.verification.kycPreparation.requirementsAus.businessBankStatement'
  )}\n>\n> [${t(
    'screens.verification.kycPreparation.requirements.mustMatchBankStatement'
  )}](banner:warning)`;
  const AUSNFP = `### ${t('screens.verification.kycNotStarted.businessTypesAus.nfp')}\n\n${t(
    'screens.verification.kycNotStarted.businessTypesAus.nfpDescription'
  )} ${t('screens.verification.kycPreparation.requirements.youWillNeed')}\n\n> * ${t(
    'screens.verification.kycPreparation.requirementsAus.constitution'
  )}\n> * ${t('screens.verification.kycPreparation.requirementsAus.governmentIdChairman')}\n> * ${t(
    'screens.verification.kycPreparation.requirementsAus.businessBankStatement'
  )}\n>\n> [${t(
    'screens.verification.kycPreparation.requirements.mustMatchBankStatement'
  )}](banner:warning)`;

  const USSoleTrader = `### ${t(
    'screens.verification.kycNotStarted.businessTypesUsa.soleTrader'
  )}\n\n${t('screens.verification.kycNotStarted.businessTypesUsa.soleTraderDescription')} ${t(
    'screens.verification.kycPreparation.requirements.youWillNeed'
  )}\n\n> * ${t('screens.verification.kycPreparation.requirementsUsa.governmentId')}\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.ssn'
  )}\n> * ${t('screens.verification.kycPreparation.requirementsUsa.bankStatement')}`;
  const USLLC = `### ${t('screens.verification.kycNotStarted.businessTypesUsa.llc')}\n\n${t(
    'screens.verification.kycNotStarted.businessTypesUsa.llcDescription'
  )} ${t('screens.verification.kycPreparation.requirements.youWillNeed')}\n\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.companyRegistration'
  )}\n> * ${t('screens.verification.kycPreparation.requirementsUsa.governmentIdDirector')}\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.businessBankStatement'
  )}`;
  const USPartnership = `### ${t(
    'screens.verification.kycNotStarted.businessTypesUsa.partnership'
  )}\n\n${t('screens.verification.kycNotStarted.businessTypesUsa.partnershipDescription')} ${t(
    'screens.verification.kycPreparation.requirements.youWillNeed'
  )}\n\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.companyRegistration'
  )}\n> * ${t('screens.verification.kycPreparation.requirementsUsa.governmentIdDirector')}\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.businessBankStatement'
  )}`;
  const USCCorp = `### ${t('screens.verification.kycNotStarted.businessTypesUsa.ccorp')}\n\n${t(
    'screens.verification.kycNotStarted.businessTypesUsa.ccorpDescription'
  )} ${t('screens.verification.kycPreparation.requirements.youWillNeed')}\n\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.companyRegistration'
  )}\n> * ${t('screens.verification.kycPreparation.requirementsUsa.governmentIdDirector')}\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.businessBankStatement'
  )}`;
  const USSCorp = `### ${t('screens.verification.kycNotStarted.businessTypesUsa.scorp')}\n\n${t(
    'screens.verification.kycNotStarted.businessTypesUsa.scorpDescription'
  )} ${t('screens.verification.kycPreparation.requirements.youWillNeed')}\n\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.companyRegistration'
  )}\n> * ${t('screens.verification.kycPreparation.requirementsUsa.governmentIdDirector')}\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.businessBankStatement'
  )}`;
  const USNFP = `### ${t('screens.verification.kycNotStarted.businessTypesUsa.nfp')}\n\n${t(
    'screens.verification.kycNotStarted.businessTypesUsa.nfpDescription'
  )} ${t('screens.verification.kycPreparation.requirements.youWillNeed')}\n\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.nfpRegistration'
  )}\n> * ${t('screens.verification.kycPreparation.requirementsUsa.governmentIdChairman')}\n> * ${t(
    'screens.verification.kycPreparation.requirementsUsa.businessBankStatement'
  )}`;

  const drivingLicenseHelp = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.governmentIdHelp.drivingLicense'
  )}\n\n${t(
    'screens.verification.kycPreparation.governmentIdHelp.drivingLicenseDescription'
  )}\n\n![${t(
    'screens.verification.kycPreparation.governmentIdHelp.drivingLicenseFront'
  )}](image.fit:drivingLicenceFront)\n![${t(
    'screens.verification.kycPreparation.governmentIdHelp.drivingLicenseBack'
  )}](image.fit:drivingLicenceBack)`;
  const drivingLicenseHelpNoPic = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.governmentIdHelp.drivingLicense'
  )}\n\n${t('screens.verification.kycPreparation.governmentIdHelp.drivingLicenseDescription')}`;
  const passportHelp = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.governmentIdHelp.passport'
  )}\n\n${t('screens.verification.kycPreparation.governmentIdHelp.passportDescription')}\n\n![${t(
    'screens.verification.kycPreparation.governmentIdHelp.passport'
  )}](image.fit:passport)`;
  const passportHelpNoPic = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.governmentIdHelp.passport'
  )}\n\n${t('screens.verification.kycPreparation.governmentIdHelp.passportDescription')}`;
  const governmentIssuedIdHelp = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.governmentIdHelp.governmentId'
  )}\n\n${t(
    'screens.verification.kycPreparation.governmentIdHelp.governmentIdDescription'
  )}\n\n![${t(
    'screens.verification.kycPreparation.governmentIdHelp.governmentId'
  )}](image.fit:governmentId)`;
  const governmentIssuedIdHelpNoPic = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.governmentIdHelp.governmentId'
  )}\n\n${t('screens.verification.kycPreparation.governmentIdHelp.governmentIdDescription')}`;
  const bankStatementHelp = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.bankStatementHelp.bankStatement'
  )}\n\n${t(
    'screens.verification.kycPreparation.bankStatementHelp.bankStatementDescription'
  )}\n\n![${t(
    'screens.verification.kycPreparation.bankStatementHelp.bankStatement'
  )}](image.fit:bankStatement)`;
  const bankStatementHelpNoPic = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.bankStatementHelp.bankStatement'
  )}\n\n${t('screens.verification.kycPreparation.bankStatementHelp.bankStatementDescription')}`;
  const bankStatementHelpUsa = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.bankStatementHelp.bankStatement'
  )}\n\n${t('screens.verification.kycPreparation.bankStatementHelp.bankStatementDescriptionUsa')}`;
  const openingLetterHelp = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.bankStatementHelp.openingLetter'
  )}\n\n${t(
    'screens.verification.kycPreparation.bankStatementHelp.openingLetterDescription'
  )}\n\n![${t(
    'screens.verification.kycPreparation.bankStatementHelp.openingLetter'
  )}](image.fit:openingLetter)`;
  const openingLetterHelpNoPic = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.bankStatementHelp.openingLetter'
  )}\n\n${t('screens.verification.kycPreparation.bankStatementHelp.openingLetterDescription')}`;
  const openingLetterHelpUsa = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.bankStatementHelp.openingLetter'
  )}\n\n${t('screens.verification.kycPreparation.bankStatementHelp.openingLetterDescriptionUsa')}`;
  const companiesHouseHelp = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.companiesHouseHelp.companiesHouse'
  )}\n\n${t(
    'screens.verification.kycPreparation.companiesHouseHelp.companiesHouseDescription'
  )}\n\n![${t(
    'screens.verification.kycPreparation.companiesHouseHelp.companiesHouse'
  )}](image.fit:companiesHouse)`;
  const utrHelp = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t('screens.verification.kycPreparation.utrHelp.utr')}\n\n${t(
    'screens.verification.kycPreparation.utrHelp.utrDescription'
  )}\n\n![${t('screens.verification.kycPreparation.utrHelp.utr')}](image.fit:utr)`;
  const constitutionHelp = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.constitutionHelp.constitution'
  )}\n\n${t(
    'screens.verification.kycPreparation.constitutionHelp.constitutionDescription'
  )}\n\n![${t(
    'screens.verification.kycPreparation.constitutionHelp.constitution'
  )}](image.fit:constitution)`;
  const constitutionHelpNoPic = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.constitutionHelp.constitution'
  )}\n\n${t('screens.verification.kycPreparation.constitutionHelp.constitutionDescription')}`;
  const socialSecurityHelp = `### ${t(
    'screens.verification.kycPreparation.socialSecurityHelp.socialSecurityNumber'
  )}\n\n${t(
    'screens.verification.kycPreparation.socialSecurityHelp.socialSecurityNumberDescription'
  )}`;
  const companyRegistrationUsaHelp = `[${t(
    'screens.verification.kycPreparation.requirements.mustBeUpToDate'
  )}](banner:info)\n### ${t(
    'screens.verification.kycPreparation.companyDocumentsUsaHelp.companyDocuments'
  )}\n\n${t(
    'screens.verification.kycPreparation.companyDocumentsUsaHelp.companyDocumentsDescription'
  )}\n* ${t('screens.verification.kycPreparation.companyDocumentsUsaHelp.irsLetter')}\n* ${t(
    'screens.verification.kycPreparation.companyDocumentsUsaHelp.taxReturn'
  )}\n* ${t('screens.verification.kycPreparation.companyDocumentsUsaHelp.salesTax')}\n\n${t(
    'screens.verification.kycPreparation.companyDocumentsUsaHelp.forMoreInformation'
  )}`;

  return isLoadingUserCountry ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '96px',
      }}
    >
      <CircularProgress
        aria-label="Loading Data"
        aria-live="polite"
        aria-busy={isLoadingUserCountry}
        data-testid="loading-icon"
        sx={{ color: 'text.secondary' }}
      />
    </Box>
  ) : (
    <>
      <Card variant="outlined" sx={{ padding: '24px' }}>
        {(!['GB', 'US', 'AU'].includes(userCountry) ||
          (userCountry === 'GB' && stepContext.businessType === 'organisation')) && (
          <Markdown
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {Generic}
          </Markdown>
        )}
        {userCountry === 'GB' && stepContext && stepContext.businessType === 'soleTrader' && (
          <Markdown
            infoHandlers={{
              govID: () => setHelpPage('governmentId'),
              bankStatement: () => setHelpPage('bankStatement'),
              drivingLicense: () => setHelpPage('drivingLicense'),
              openingLetter: () => setHelpPage('openingLetter'),
              passport: () => setHelpPage('passport'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {UKSoleTrader}
          </Markdown>
        )}

        {userCountry === 'AU' && stepContext && stepContext.businessType === 'soleTrader' && (
          <Markdown
            infoHandlers={{
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatement: () => setHelpPage('bankStatementNoPic'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetter: () => setHelpPage('openingLetterNoPic'),
              passport: () => setHelpPage('passportNoPic'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {AUSSoleTrader}
          </Markdown>
        )}
        {userCountry === 'AU' && stepContext && stepContext.businessType === 'ltd' && (
          <Markdown
            infoHandlers={{
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatement: () => setHelpPage('bankStatementNoPic'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetter: () => setHelpPage('openingLetterNoPic'),
              passport: () => setHelpPage('passportNoPic'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {AUSLimited}
          </Markdown>
        )}
        {userCountry === 'AU' && stepContext && stepContext.businessType === 'nfp' && (
          <Markdown
            infoHandlers={{
              constitution: () => setHelpPage('constitutionNoPic'),
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatement: () => setHelpPage('bankStatementNoPic'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetter: () => setHelpPage('openingLetterNoPic'),
              passport: () => setHelpPage('passportNoPic'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {AUSNFP}
          </Markdown>
        )}
        {userCountry === 'AU' && stepContext && stepContext.businessType === 'partnership' && (
          <Markdown
            infoHandlers={{
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatement: () => setHelpPage('bankStatementNoPic'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetter: () => setHelpPage('openingLetterNoPic'),
              passport: () => setHelpPage('passportNoPic'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {AUSPartnership}
          </Markdown>
        )}

        {userCountry === 'US' && stepContext && stepContext.businessType === 'soleTrader' && (
          <Markdown
            infoHandlers={{
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatementUsa: () => setHelpPage('bankStatementUsa'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetterUsa: () => setHelpPage('openingLetterUsa'),
              passport: () => setHelpPage('passportNoPic'),
              socialSecurity: () => setHelpPage('socialSecurity'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {USSoleTrader}
          </Markdown>
        )}
        {userCountry === 'US' && stepContext && stepContext.businessType === 'llc' && (
          <Markdown
            infoHandlers={{
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatementUsa: () => setHelpPage('bankStatementUsa'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetterUsa: () => setHelpPage('openingLetterUsa'),
              passport: () => setHelpPage('passportNoPic'),
              companyRegistrationUsa: () => setHelpPage('companyRegistrationUsa'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {USLLC}
          </Markdown>
        )}
        {userCountry === 'US' && stepContext && stepContext.businessType === 'nfp' && (
          <Markdown
            infoHandlers={{
              constitution: () => setHelpPage('constitutionNoPic'),
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatementUsa: () => setHelpPage('bankStatementUsa'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetterUsa: () => setHelpPage('openingLetterUsa'),
              passport: () => setHelpPage('passportNoPic'),
              companyRegistrationUsa: () => setHelpPage('companyRegistrationUsa'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {USNFP}
          </Markdown>
        )}
        {userCountry === 'US' && stepContext && stepContext.businessType === 'partnership' && (
          <Markdown
            infoHandlers={{
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatementUsa: () => setHelpPage('bankStatementUsa'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetterUsa: () => setHelpPage('openingLetterUsa'),
              passport: () => setHelpPage('passportNoPic'),
              companyRegistrationUsa: () => setHelpPage('companyRegistrationUsa'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {USPartnership}
          </Markdown>
        )}
        {userCountry === 'US' && stepContext && stepContext.businessType === 'ccorp' && (
          <Markdown
            infoHandlers={{
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatementUsa: () => setHelpPage('bankStatementUsa'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetterUsa: () => setHelpPage('openingLetterUsa'),
              passport: () => setHelpPage('passportNoPic'),
              companyRegistrationUsa: () => setHelpPage('companyRegistrationUsa'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {USCCorp}
          </Markdown>
        )}
        {userCountry === 'US' && stepContext && stepContext.businessType === 'scorp' && (
          <Markdown
            infoHandlers={{
              govID: () => setHelpPage('governmentIdNoPic'),
              bankStatementUsa: () => setHelpPage('bankStatementUsa'),
              drivingLicenseNoPic: () => setHelpPage('drivingLicenseNoPic'),
              openingLetterUsa: () => setHelpPage('openingLetterUsa'),
              passport: () => setHelpPage('passportNoPic'),
              companyRegistrationUsa: () => setHelpPage('companyRegistrationUsa'),
            }}
            css={{
              infoLink: { fontSize: '16px' },
              p: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
              strong: { color: colorMode === 'light' ? '#041123' : '#F2F2F2' },
            }}
          >
            {USSCorp}
          </Markdown>
        )}
      </Card>
      {helpPage === 'drivingLicense' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ drivingLicenceFront, drivingLicenceBack }}
        >
          {drivingLicenseHelp}
        </HelpPanel>
      )}
      {helpPage === 'drivingLicenseNoPic' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
        >
          {drivingLicenseHelpNoPic}
        </HelpPanel>
      )}
      {helpPage === 'passport' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ passport }}
        >
          {passportHelp}
        </HelpPanel>
      )}
      {helpPage === 'passportNoPic' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
        >
          {passportHelpNoPic}
        </HelpPanel>
      )}
      {helpPage === 'governmentId' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ governmentId }}
        >
          {governmentIssuedIdHelp}
        </HelpPanel>
      )}
      {helpPage === 'governmentIdNoPic' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
        >
          {governmentIssuedIdHelpNoPic}
        </HelpPanel>
      )}
      {helpPage === 'bankStatement' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ bankStatement }}
        >
          {bankStatementHelp}
        </HelpPanel>
      )}
      {helpPage === 'bankStatementNoPic' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
        >
          {bankStatementHelpNoPic}
        </HelpPanel>
      )}
      {helpPage === 'bankStatementUsa' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ bankStatement }}
        >
          {bankStatementHelpUsa}
        </HelpPanel>
      )}
      {helpPage === 'openingLetterUsa' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ openingLetter }}
        >
          {openingLetterHelpUsa}
        </HelpPanel>
      )}
      {helpPage === 'openingLetter' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ openingLetter }}
        >
          {openingLetterHelp}
        </HelpPanel>
      )}
      {helpPage === 'openingLetterNoPic' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
        >
          {openingLetterHelpNoPic}
        </HelpPanel>
      )}
      {helpPage === 'companiesHouseDocument' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ companiesHouse }}
        >
          {companiesHouseHelp}
        </HelpPanel>
      )}
      {helpPage === 'utr' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ utr }}
        >
          {utrHelp}
        </HelpPanel>
      )}
      {helpPage === 'constitution' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
          images={{ constitution }}
        >
          {constitutionHelp}
        </HelpPanel>
      )}
      {helpPage === 'constitutionNoPic' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
        >
          {constitutionHelpNoPic}
        </HelpPanel>
      )}
      {helpPage === 'socialSecurity' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
        >
          {socialSecurityHelp}
        </HelpPanel>
      )}
      {helpPage === 'companyRegistrationUsa' && (
        <HelpPanel
          onClose={() => setHelpPage(undefined)}
          title={t('screens.verification.kycPreparation.requirements.documentsNeeded')}
        >
          {companyRegistrationUsaHelp}
        </HelpPanel>
      )}
    </>
  );
};

export default PrepareForKYC;
