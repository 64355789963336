import * as React from 'react';
import { useCallback, useMemo, useContext } from 'react';
import { Box, Card, CircularProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import AccordionPicker, { AccordionPickerOption } from '../../../components/AccordionPicker';
import { useSteps } from '../../../components/StepController';
import { useUserCountry } from '../../../helpers/useCountries';
import { UserContext } from '../../../context/UserContext';

const SelectBusinessType = () => {
  const { t } = useTranslation();
  const stepContext = useSteps();
  const { userCountry, isLoading: isLoadingUserCountry } = useUserCountry();
  const { locale } = useContext(UserContext);
  const theme = useTheme();

  const options: AccordionPickerOption[] = useMemo(() => {
    switch (userCountry) {
      case 'US':
        return [
          {
            id: 'soleTrader',
            name: t('screens.verification.kycNotStarted.businessTypesUsa.soleTrader'),
            description: t(
              'screens.verification.kycNotStarted.businessTypesUsa.soleTraderDescription'
            ),
          },
          {
            id: 'llc',
            name: t('screens.verification.kycNotStarted.businessTypesUsa.llc'),
            description: t('screens.verification.kycNotStarted.businessTypesUsa.llcDescription'),
          },
          {
            id: 'nfp',
            name: t('screens.verification.kycNotStarted.businessTypesUsa.nfp'),
            description: t('screens.verification.kycNotStarted.businessTypesUsa.nfpDescription'),
          },
          {
            id: 'partnership',
            name: t('screens.verification.kycNotStarted.businessTypesUsa.partnership'),
            description: t(
              'screens.verification.kycNotStarted.businessTypesUsa.partnershipDescription'
            ),
          },
          {
            id: 'ccorp',
            name: t('screens.verification.kycNotStarted.businessTypesUsa.ccorp'),
            description: t('screens.verification.kycNotStarted.businessTypesUsa.ccorpDescription'),
          },
          {
            id: 'scorp',
            name: t('screens.verification.kycNotStarted.businessTypesUsa.scorp'),
            description: t('screens.verification.kycNotStarted.businessTypesUsa.scorpDescription'),
          },
        ];
      case 'AU':
        return [
          {
            id: 'soleTrader',
            name: t('screens.verification.kycNotStarted.businessTypesAus.soleTrader'),
            description: t(
              'screens.verification.kycNotStarted.businessTypesAus.soleTraderDescription'
            ),
          },
          {
            id: 'ltd',
            name: t('screens.verification.kycNotStarted.businessTypesAus.ltd'),
            description: t('screens.verification.kycNotStarted.businessTypesAus.ltdDescription'),
          },
          {
            id: 'nfp',
            name: t('screens.verification.kycNotStarted.businessTypesAus.nfp'),
            description: t('screens.verification.kycNotStarted.businessTypesAus.nfpDescription'),
          },
          {
            id: 'partnership',
            name: t('screens.verification.kycNotStarted.businessTypesAus.partnership'),
            description: t(
              'screens.verification.kycNotStarted.businessTypesAus.partnershipDescription'
            ),
          },
        ];
      case 'ES':
      case 'CA':
      case 'IE':
      case 'NZ':
        return [
          {
            id: 'soleTrader',
            name: t('screens.verification.kycNotStarted.businessTypes.soleTrader'),
            description: t(
              'screens.verification.kycNotStarted.businessTypes.soleTraderDescription'
            ),
          },
          {
            id: 'organisation',
            name: t('screens.verification.kycNotStarted.businessTypes.organisation'),
            description: t(
              'screens.verification.kycNotStarted.businessTypes.organisationDescription'
            ),
          },
        ];
      case 'GB':
        return [
          {
            id: 'soleTrader',
            name: t('screens.verification.kycNotStarted.businessTypes.soleTrader'),
            description: t(
              'screens.verification.kycNotStarted.businessTypes.soleTraderDescription'
            ),
          },
          {
            id: 'organisation',
            name: t('screens.verification.kycNotStarted.businessTypes.organisation'),
            description: t(
              'screens.verification.kycNotStarted.businessTypes.organisationDescription'
            ),
          },
        ];
      default:
        return [
          {
            id: 'soleTrader',
            name: t('screens.verification.kycNotStarted.businessTypes.soleTrader'),
            description: t(
              'screens.verification.kycNotStarted.businessTypes.soleTraderDescription'
            ),
          },
          {
            id: 'ltd',
            name: t('screens.verification.kycNotStarted.businessTypes.ltd'),
            description: t('screens.verification.kycNotStarted.businessTypes.ltdDescription'),
          },
          {
            id: 'cic',
            name: t('screens.verification.kycNotStarted.businessTypes.cic'),
            description: t('screens.verification.kycNotStarted.businessTypes.cicDescription'),
          },
          {
            id: 'nfp',
            name: t('screens.verification.kycNotStarted.businessTypes.nfp'),
            description: t('screens.verification.kycNotStarted.businessTypes.nfpDescription'),
          },
          {
            id: 'partnership',
            name: t('screens.verification.kycNotStarted.businessTypes.partnership'),
            description: t(
              'screens.verification.kycNotStarted.businessTypes.partnershipDescription'
            ),
          },
        ];
    }
  }, [userCountry]);

  const onSelectOption = useCallback(
    (option) => {
      if (!stepContext) return;
      stepContext.setBusinessType(option.id);
      stepContext.updateCurrentStep({ error: '', completable: false });
    },
    [stepContext]
  );

  return isLoadingUserCountry ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '96px',
      }}
    >
      <CircularProgress
        aria-label="Loading Data"
        aria-live="polite"
        aria-busy={isLoadingUserCountry}
        data-testid="loading-icon"
        sx={{ color: 'text.secondary' }}
      />
    </Box>
  ) : (
    <Card variant="outlined" sx={{ padding: '24px' }}>
      <Typography component="h2" variant="h2" sx={{ marginBottom: '8px' }}>
        {t('screens.verification.kycNotStarted.businessType')}
      </Typography>
      <Typography sx={{ marginBottom: '24px', color: 'text.secondary' }}>
        {t('screens.verification.kycNotStarted.selectBusinessType')}
      </Typography>
      <AccordionPicker
        key={stepContext?.businessType}
        options={options}
        onSelectOption={onSelectOption}
        selectedOption={stepContext?.businessType || undefined}
      />
      <AlertMessage
        type="info"
        styles={{ marginTop: '24px' }}
        iconAlignment="baseline"
        locale={locale}
        theme={theme}
      >
        {t('screens.verification.kycNotStarted.informationUploadDocuments')}
      </AlertMessage>
    </Card>
  );
};

export default SelectBusinessType;
