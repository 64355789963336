import React from 'react';
import { Box } from '@mui/material';

const IconAndText = ({
  icon,
  textComponent,
}: {
  icon: JSX.Element;
  textComponent: JSX.Element;
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', maxWidth: '100%', gap: '0.8rem' }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: { xs: 'center', sm: 'flex-start' },
        alignItems: { xs: 'flex-start', sm: 'center' },
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        minWidth: '2.5rem',
      }}
    >
      {icon}
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '0.5rem',
      }}
    >
      {textComponent}
    </Box>
  </Box>
);

export default IconAndText;
