import mediaQuery from 'css-mediaquery';
import { AccessRights, UserAccessRights, UserRole } from '../types';

export const slugify = (string: string) =>
  string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const deepClone = (obj: unknown) => JSON.parse(JSON.stringify(obj));

export const initials = (name: string) =>
  name
    ?.match(/(^\S?|\s\S)?/g)
    ?.map((v) => v.trim())
    .join('')
    .match(/(^\S|\S$)?/g)
    ?.join('')
    .toLocaleUpperCase();

export const createMatchMedia = (width: any) => (query: any) => ({
  matches: mediaQuery.match(query, {
    width,
  }),
  addEventListener: jest.fn(),
  removeEventListener: jest.fn(),

  media: query,
  onchange: null,
  addListener: jest.fn(),
  removeListener: jest.fn(),
  dispatchEvent: jest.fn(),
});

export const formatDates = (date: Date, date2: Date) => {
  const formattedDate1 = new Date(date.toDateString());
  const formattedDate2 = new Date(date2.toDateString());
  formattedDate1.setUTCHours(0, 0, 0, 0);
  formattedDate2.setUTCHours(0, 0, 0, 0);
  return [formattedDate1.getTime(), formattedDate2.getTime()];
};

export const dateTodayOrAfter = (dates: number[]) => dates[0] >= dates[1];
export const datesAreTheSame = (dates: number[]) => dates[0] === dates[1];


const getAddressComponentOfTypeLongName = (addressComponents: [any], typeToFind: string) => {
  const result = addressComponents.find((addressComponent: any) =>
    addressComponent.Types.find((type: any) => type === typeToFind)
  );
  return result?.LongName;
};

export const getAddressComponentOfTypeShortName = (
  addressComponents: [any],
  typeToFind: string
) => {
  const result = addressComponents.find((addressComponent: any) =>
    addressComponent.Types.find((type: any) => type === typeToFind)
  );
  return result?.ShortName;
};

export const constructGBAddress = (addressComponents: any) => {
  const postalCode = getAddressComponentOfTypeLongName(addressComponents, 'postal_code');
  const streetNumber = getAddressComponentOfTypeLongName(addressComponents, 'street_number');
  const route = getAddressComponentOfTypeLongName(addressComponents, 'route');
  const postalTown = getAddressComponentOfTypeLongName(addressComponents, 'postal_town');
  const county = getAddressComponentOfTypeLongName(
    addressComponents,
    'administrative_area_level_2'
  );

  return {
    postcode: postalCode,
    streetName: streetNumber ? `${streetNumber} ${route}` : route,
    city: postalTown,
    county,
  };
};

export const constructUSAddress = (addressComponents: any) => {
  const postalCode = getAddressComponentOfTypeLongName(addressComponents, 'postal_code');
  const postalCodeSuffix = getAddressComponentOfTypeLongName(
    addressComponents,
    'postal_code_suffix'
  );
  const streetNumber = getAddressComponentOfTypeLongName(addressComponents, 'street_number');
  const route = getAddressComponentOfTypeLongName(addressComponents, 'route');
  const locality = getAddressComponentOfTypeLongName(addressComponents, 'locality');
  const province = getAddressComponentOfTypeLongName(
    addressComponents,
    'administrative_area_level_1'
  );

  return {
    postcode: postalCodeSuffix ? postalCode + postalCodeSuffix : postalCode,
    streetName: streetNumber ? `${streetNumber} ${route}` : route,
    city: locality,
    county: province,
  };
};

export const constructDefaultAddress = (addressComponents: any) => {
  const postalCode = getAddressComponentOfTypeLongName(addressComponents, 'postal_code');
  const streetNumber = getAddressComponentOfTypeLongName(addressComponents, 'street_number');
  const route = getAddressComponentOfTypeLongName(addressComponents, 'route');
  const locality = getAddressComponentOfTypeLongName(addressComponents, 'locality');
  const province = getAddressComponentOfTypeLongName(
    addressComponents,
    'administrative_area_level_1'
  );

  return {
    postcode: postalCode,
    streetName: streetNumber ? `${streetNumber} ${route}` : route,
    city: locality,
    county: province,
  };
};

export const validatePhoneNumber = (val: string, country: string) => {
  if (val && country === "GB") {
    return Boolean(val.match(/^(\+44\d{10}|\+440\d{10})$/));
  }
  if (val && country === "US") {
    return Boolean(val.match(/^\+1(\([0-9]{3}\)\s?|[0-9]{3}-?)[0-9]{3}-?[0-9]{4}$/));
  }
  if (val && country === "AU") {
    return Boolean(val.match(/^\+61(\+\d{2}[ -]{0,1}){0,1}(((\({0,1}[ -]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ -]*(\d{4}[ -]{0,1}\d{4}))|(1[ -]{0,1}(300|800|900|902)[ -]{0,1}((\d{6})|(\d{3}[ -]{0,1}\d{3})))|(13[ -]{0,1}([\d -]{5})|((\({0,1}[ -]{0,1})0{0,1}\){0,1}4{1}[\d -]{8,10})))$/));
  }
  if (val && country === "ES") {
    return Boolean(val.match(/^\+34\s?[6789](?:\d{8}|\d{2} \d{3} \d{3}|\d \d{3} \d{2} \d{2}|\d \d{3} \d{3}|\d{2} \d{2} \d{2} \d{2}|\d \d{3} \d{4})$/));
  }
  if (val && country === "IE") {
    return Boolean(val.match(/^\+353(1\d{7,8}|[2-9]\d{6,7}|8[35679]\d{7})$/));
  }
  if (val && country === "NZ") {
    return Boolean(val.match(/^\+64(3\d{7,8}|4\d{7,8}|6\d{7,8}|7\d{7,8}|9\d{7,8}|2\d{8,9})$/));
  }
  if (val && country === "CA") {
    return Boolean(val.match(/^\+1(\([0-9]{2}\))?([0-9]{3}-?)?([0-9]{3})-?([0-9]{4})(\/[0-9]{4})?$/));
  }
  return !!val; // if we have a number but no validation for the user country, just pass
}

export const validatePostCode = (val: string, country: string) => {
  if (val && country === "GB") {
    return Boolean(val.match(/^[a-zA-Z]{1,2}\d{1}[0-9a-zA-Z]?( ?\d{1}[a-zA-Z]{2})?$/));
  }

  if (val && country === "US") {
    return Boolean(val.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/));
  }

  if (val && country === "AU") {
    return Boolean(val.match(/^\d{4}$/));
  }

  if (val && country === "ES") {
    return Boolean(val.match(/^\d{5}$/));
  }

  if (val && country === "IE") {
    return Boolean(val.match(/^[A-Za-z]\d[\dWw] [\dA-Za-z]{4}$/));
  }

  if (val && country === "CA") {
    return Boolean(val.match(/^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/));
  }

  if (val && country === "NZ") {
    return Boolean(val.match(/^\d{4}$/));
  }

  return !!val; // if we have a post code but no validation for the user country, just pass
}

export const sanitizeAlphanumeric = (val: string) => val.replaceAll(/[^a-zA-Z\u00C0-\u017F\s]+/gi, "");

export const getBaseDomain = (domain: string) => {
  const split = new URL(domain).hostname.split(".");
  return split.length <= 2 ? split.join(".") : split.slice(1).join(".")
}

export const hasAtLeastOneModule = (modules: UserRole[] | undefined, targetModules: string[]): boolean => {
  if (!modules) {
    return false;
  }

  return modules.filter((el) => targetModules.includes(el.RoleName)).length > 0;
}

export const hasModule = (modules: UserRole[] | undefined, module: string): boolean => {
  if (!modules) {
    return false;
  }

  return modules.filter((el) => el.RoleName === module).length > 0;
}

export const hasAccessRights = (userAccessRights: UserAccessRights | undefined, accessRight: AccessRights, locationArea?: number): boolean => {
  if (!userAccessRights) {
    return false;
  }

  if (accessRight === 'LocationAreaID') {
    return userAccessRights.LocationAreaID === null || locationArea === userAccessRights.LocationAreaID
  }

  return userAccessRights[accessRight] || false;
}


const referralProgIds: Record<string, string> = {
  GB: "05b01e66-3c76-45c8-9426-99d51bf440e2",
  US: "967d2142-9fd6-475e-af17-7667bbc93378",
  AU: "bb61a612-058c-4563-bd30-d3d60a0caf25",
  CA: "a1842326-eb86-45bd-8d07-7343868e82ab",
  MX: "685ca9d9-d6a1-4fc3-8dbd-d34f22b4f2f5",
  ES: "24266c28-be04-4dc7-a882-ac0750f3805e",
  NZ: "144c5475-9902-4753-a9e2-5c3e2e1d9162",
};

export const getReferralLink = (companyId: string, companyEmail: string, companyName: string, userTerritory: string): string => {
  if (!userTerritory || !Object.keys(referralProgIds).includes(userTerritory)) return "";
  return `https://eposnow.referralrock.com/access/?programidentifier=${referralProgIds[userTerritory]}&externalid=${companyId}&lastname=${companyName}&email=${companyEmail}`;
}
