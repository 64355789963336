type IndustryTypesTranslation = {
  id: number;
  name: string;
};

const industryTypesEsTranslations: IndustryTypesTranslation[] = [
  { id: 1, name: 'Artículos de belleza' },
  { id: 2, name: 'Carnicería' },
  { id: 3, name: 'Materiales de construcción' },
  { id: 4, name: 'Recambios y accesorios de vehículos' },
  { id: 5, name: 'Tienda de beneficencia' },
  { id: 6, name: 'Tienda de ropa (Ropa / Moda)' },
  { id: 7, name: 'Cigarrillos electrónicos / Tienda de vapeadores' },
  { id: 8, name: 'Minorista de electrónica' },
  { id: 9, name: 'Librería' },
  { id: 10, name: 'Tienda de música' },
  { id: 11, name: 'Suministros de granja / rancho' },
  { id: 12, name: 'Florista' },
  { id: 13, name: 'Centro de jardinería (productos de jardín)' },
  { id: 14, name: 'Tienda general / Tienda de comestibles / Periódicos / Tienda de alimentación' },
  { id: 15, name: 'Tienda de regalos' },
  { id: 16, name: 'Armas y equipo táctico (EE. UU.)' },
  { id: 17, name: 'Artículos para el hogar (muebles y decoración del hogar)' },
  { id: 18, name: 'Joyería (joyería)' },
  { id: 19, name: 'Licorería' },
  { id: 20, name: 'Casa de empeño' },
  { id: 21, name: 'Tienda de mascotas (mascotas y suministros)' },
  { id: 22, name: 'Gasolinera' },
  { id: 23, name: 'Farmacia' },
  { id: 24, name: 'Revendedor / Distribuidor' },
  { id: 25, name: 'Zapatería (calzado)' },
  { id: 26, name: 'Artículos deportivos' },
  { id: 27, name: 'Supermercado' },
  { id: 28, name: 'Estanco' },
  { id: 29, name: 'Herramientas / Ferretería' },
  { id: 30, name: 'Bar' },
  { id: 31, name: 'Café' },
  { id: 32, name: 'Cafetería' },
  { id: 33, name: 'Club de caballeros' },
  { id: 34, name: 'Hotel' },
  { id: 35, name: 'Club nocturno' },
  { id: 36, name: 'Restaurante' },
  { id: 37, name: 'Club social' },
  { id: 38, name: 'Salón / Peluquería / Barbero' },
  { id: 39, name: 'Salón de belleza' },
  { id: 40, name: 'Esteticién' },
  { id: 41, name: 'Centro de masajes / terapia' },
  { id: 42, name: 'Spa' },
  { id: 43, name: 'Gimnasio' },
  { id: 44, name: 'Lavadero de coches' },
  { id: 45, name: 'Tintorerías' },
  { id: 46, name: 'Tienda de reparación' },
  { id: 47, name: 'Centro de actividades / juegos' },
  { id: 48, name: 'Cafetería / Tienda combinada' },
  { id: 49, name: 'Compra al por mayor' },
  { id: 50, name: 'Cine / Bolera / Parque de trampolines' },
  { id: 51, name: 'Centro de ocio' },
  { id: 52, name: 'Museo' },
  { id: 53, name: 'Estadio / Arena' },
  { id: 54, name: 'Universidad' },
  { id: 55, name: 'Comida / Bebida Bar / Carrito / Popup / Concesión / Móvil' },
  { id: 56, name: 'Restaurante de comida rápida / servicio rápido' },
  { id: 57, name: 'Popup minorista' },
  { id: 58, name: 'Para llevar o panadería / confitería (sin asientos)' },
  { id: 59, name: 'No listada' },
  { id: 60, name: 'Charcutería' },
  { id: 61, name: 'Panadería' },
  { id: 62, name: 'Club deportivo profesional' },
  { id: 63, name: 'Quiosco de prensa' },
  { id: 64, name: 'Tienda de alimentación' },
  { id: 65, name: 'Tienda de comestibles' },
  { id: 66, name: 'Tienda de juegos' },
  { id: 67, name: 'Tienda minorista especializada' },
  { id: 68, name: 'Galería de arte' },
  { id: 69, name: 'Artes y Oficio' },
  { id: 70, name: 'Servicios de alquiler' },
  { id: 71, name: 'Cervecería / Destilería' },
  { id: 72, name: 'Copistería' },
  { id: 73, name: 'Outlet' },
  { id: 74, name: 'Centro educativo' },
  { id: 75, name: 'Lugar de eventos' },
  { id: 76, name: 'Tienda de juguetes' },
  { id: 77, name: 'Centro deportivo / Club deportivo' },
  { id: 78, name: 'Tienda de salud y fitness' },
  { id: 79, name: 'Camping / Camping caravanas' },
  { id: 80, name: 'Pub' },
  { id: 81, name: 'Material de oficina' },
  { id: 82, name: 'Centro de información / ayuda' },
  { id: 83, name: 'Centro recreativo' },
];

export default industryTypesEsTranslations;
