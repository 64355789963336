import { KYCResponseType } from '../../types';

const checkKYCPassed = (kycResponse: KYCResponseType) => {
  if (
    // if HasPassedKyc is true, then KYC has for sure passed, return true
    kycResponse?.HasPassedKyc ||
    // if HasPassedKyc is false, KYC can be true if the user has chosen to create a MyBusinessAccount
    // For that, the next condition is the verification for both My Business and External Accounts
    (kycResponse?.ReceiveFromBalanceAccount &&
      kycResponse?.ReceiveFromPlatformPayments &&
      kycResponse?.SendToBalanceAccount &&
      kycResponse?.ReceivePayments &&
      (kycResponse?.SendToTransferInstrument || kycResponse?.IssueBankAccount !== null))
  ) {
    return true;
  }

  return false;
};

export default checkKYCPassed;
